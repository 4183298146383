import { useState, useEffect, useContext } from 'react';
import axios from '../../../tools/axiosWrapper'

import { ReactComponent as PlusSVG } from '../../../media/ui_symbols/plus.svg';
import { SlideContext } from '../slideContext.js'

import _ from 'lodash';
import { useForm } from "react-hook-form";
import anime from 'animejs/lib/anime.es.js';
import './addtag.css'


const AddTag = ( {_tags, set_tags} ) => {

  const { register, resetField, handleSubmit } = useForm();
  const onSubmit = (data) => {
    set_tags(tags => ([...tags, data.newtag]))
    resetField('newtag')
  }


  const keyPress = (e) => {
     if(e.keyCode === 13){
       e.target.blur();
     }
  }

  return (
    <form
      className={'addtag'}
      onBlur={ handleSubmit(onSubmit) }
      onKeyDown={(e) => keyPress(e)}
      >
      <input
        type='text' { ...register('newtag', {minLength: 2}) }
        placeholder={'add tag'}
        />

    </form>

  );
}


export default AddTag
