import { useEffect, useState } from 'react';
import axios from '../../tools/axiosWrapper'


const AfterLogin = () => {

    const SUBMIT_NONE = 0;
    const SUBMIT_START = 1;
    const SUBMIT_SUCCESS = 2;
    const SUBMIT_ERROR = 3;

    const [user, setUser] = useState({});
    const [inputs, setInputs] = useState({
        first_name: "",
        last_name: "",
        sponsor_email: ""
    });
    const [formState, setFormState] = useState({
        isLoading: true,
        submitStatus: SUBMIT_NONE,
        errorMessage: null
    });

    useEffect(() => {
        async function fetchUser() {
            setFormState(state => ({ ...state, isLoading: true }));
            try {
                const { data } = await axios.get('/auth/me');
                setUser(data);
                setInputs(data);
            } catch (error) {
                // TODO: Do something if querying the user's info doesn't work for some reason.
            }
            setFormState(state => ({ ...state, isLoading: false }));
        }
        fetchUser();
    }, []);


    const handleChange = (event) => {

        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))

    };

    const handleSubmit = async (event) => {
      event.preventDefault();
      setFormState(state => ({ ...state, submitStatus: SUBMIT_START }));
      try {
          await axios.post('/auth/settings', inputs);
          setFormState(state => ({ ...state, submitStatus: SUBMIT_SUCCESS, errorMessage: null }));
      } catch (error) {
          // Do something if changing the user's settings doesn't work for some reason.
          const errorMessage = error.response.data.message;
          setFormState(state => ({ ...state, submitStatus: SUBMIT_ERROR, errorMessage }));
      }
    };


    function SettingsForm({ formState, inputs }) {
        if (formState.isLoading) {
            return (
                <p>Loading...</p>
            );
        } else {
            return (
                <div>
                    <h2>User Settings</h2>
                    <form onSubmit={handleSubmit}>
                        <p>
                            <label>First Name: </label>
                            <input name="first_name" type="text" value={inputs.first_name || ""} onChange={handleChange}></input>
                        </p>

                        <p>
                            <label>Last Name: </label>
                            <input name="last_name" type="text" value={inputs.last_name || ""} onChange={handleChange}></input>
                        </p>

                        <p>
                            <label>Personal Email: </label>
                            <input type="text" value={user.email} readOnly></input>
                        </p>

                        <p>
                            <hr />
                        </p>

                        <p>
                            <label>Organization Type: </label>
                            <br />

                            <input type="radio" name="organization" id="student" value="student" />
                            <label for="student">Student</label>
                            <br />
                            <input type="radio" name="organization" id="researcher" value="researcher" />
                            <label for="researcher">Researcher</label>
                            <br />
                            <input type="radio" name="organization" id="scholar" value="scholar" />
                            <label for="scholar">Scholar</label>
                            <br />
                            <input type="radio" name="organization" id="journalist" value="journalist" />
                            <label for="journalist">Journalist</label>
                            <br />
                        </p>

                        <p>
                            <label>Sponsor Email: </label>
                            <input name="sponsor_email" type="text" value={inputs.sponsor_email || ""} onChange={handleChange}></input>
                        </p>

                        <p>
                            <button type="submit" disabled={formState.submitStatus === SUBMIT_START}>Save Changes</button>
                        </p>
                    </form>
                </div>
            );
        }
    }

    function SubmitMessage({ formState }) {
        let text = "";
        switch (formState.submitStatus) {
            case SUBMIT_START:
                text = "Saving...";
                break;
            case SUBMIT_SUCCESS:
                text = "Settings saved."
                break;
            case SUBMIT_ERROR:
                text = "Something went wrong. Please check your form."
                break;
            default:
                text = "";
                break;
        }

        return (
            <p>{text}</p>
        );
    }

    return (
        <div>
            <div className={'swipe'} tabIndex='-1'>
            </div>

            <main className='after_login' role='main'>
                <SettingsForm formState={formState} inputs={inputs} />
                <SubmitMessage formState={formState} />
                <p>{formState.errorMessage ? "ERROR: " + formState.errorMessage : ""}</p>
            </main>
        </div>
    );
};

export default AfterLogin;
