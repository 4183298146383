import React from 'react';
import { useState, useEffect } from 'react';

import { Route, Routes, Outlet, useLocation , Navigate } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";


import User from './main/user/user.js';

// import Library from './main/library/library.js';

import Header from './lobby/header/header.js'
import About from './lobby/pages/about.js'
import Home from './lobby/pages/home.js'
import After_login from './lobby/pages/after_login.js'

import Loading2 from './tools/loading2.js'


import './lobby/lobby.css'
import './lobby/small.css'
import './lobby/pages/page.css'

import axios from './tools/axiosWrapper'

function App() {

  let location = useLocation();

  const pages = [
    {
      "name": "home",
      "path": "/",
    },
    {
      "name": "about",
      "path": "about",
      "h1": "fbarchive.org is an online curated collection of the leaked Facebook documents.",
      "p": "The goal is to provide researchers, students and journalists, around the world, with searchable, indexed access to the leaked Facebook documents. The documents are from a team at Facebook devoted to social science and data analytics that is supposed to help the company's leaders understand the consequences of their policies and technological designs. As such, the documents provide a wealth of academic study."
    },
    {
      "name": "signup",
      "path": "signup",
    },
    {
      "name": "login",
      "path": "login",
    },

  ]


  const [isLogged, setIsLogged] = useState(2)

  function PrivateRoute({ isLogged }) {
    return isLogged ? <Outlet /> : <Navigate to='/' />;
  }

  axios
    .get('/auth/status')
    .then( res => { return (res.data === 'access granted') ? 1 : 0 } )
    .then( res => { setIsLogged(res) })

  console.log(isLogged)

  return (
    <>
        <Routes location={location}>

          <Route path='/' element={<Header />}>
            <Route index element={<Home page={pages[0]} />} />
            <Route path='about' element={<About page={pages[1]} />} />
            <Route path='after_login' element={<After_login />} />
          </Route>

          {
            isLogged
            ?
              <Route path='/user/*' element={<User location={
                {...location,
                  ...(location.pathname.split('/')[2]=='doc' && {docid:location.pathname.split('/')[3]}),
                }
              } />} />
            : <Route path='/user' element={<p>Not logged in properly or access not granted</p>} />
          }



          {/*element={
            (isLogged == 1)
              ? <Outlet />
              : (isLogged == 0)
                ? <Navigate to='/' />
                : <Loading2 />}
            <Route index element={<Library />} />
            <Route path={'doc'}>
              <Route index />
              <Route path={':slug'} element={<Plan />} />
            </Route>
          </Route>*/}

        </Routes>
    </>
  );




}

export default App;
