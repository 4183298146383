import { useContext } from 'react';
import Img from './img.js'
import ImgWait from './imgwait.js'
import { ArchContext } from '../../user/context.js'


const Imgs = ({ _waitlist, _list, _now, set_now, set_my, _src }) => {

  const wait = _waitlist.map(item => {
    return(
      <ImgWait
        item={item}
        _now={_now}
        set_now={set_now}/>
    )
  })

  const done = _list.map(item => {
    return(
      <Img
        item={item}
        _now={_now}
        set_now={set_now}
        set_my={set_my}/>
    )
  })

  const { id, set_id } = useContext(ArchContext);


  return (
    <>
      <div className='list'>
        <div className='list-bookend'></div>

          { _src ? !_src.images.length ? <p className='list-placeholder'>no images</p> : <></> : <></>}
          { _list.length ? done : <></> }
        <div className='list-bookend'></div>
        <div className='list-space'></div>
      </div>
    </>
  );

}


export default Imgs
