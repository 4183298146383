import { useEffect } from 'react';

import { Link, Outlet } from "react-router-dom";

import './header.css'

import anime from 'animejs/lib/anime.es.js';

import useWindowDimensions from '../../tools/getWindowDimensions.js'
import $axios from '../../tools/axiosWrapper.js';


const Header = ({ pages, access, setAccess }) => {

  // const { width } = useWindowDimensions();

  const randomLogoLeft = [13,21,11,15,3]
  const randomLogoWidth = [24,25,32, 40, 42]

  useEffect(() => {

    anime.timeline({
      easing: 'easeInOutSine',
      loop: false
    })
    .add({
      targets: ".logo > div",
      duration: 600,
      width: [0,50],
      delay: anime.stagger(12, {easing: 'easeInSine', direction: 'reverse'})
    }, '-=1000')
    .add({
      targets: "header nav button",
      duration: 600,
      opacity: [0, 1],
      delay: anime.stagger(80, {easing: 'easeInSine' })
    }, '-=400')
    .add({
      targets: ".home > *",
      duration: 600,
      opacity: [0, 1],
      delay: anime.stagger(80, {easing: 'easeInSine' })
    }, '-=400')
    .add({
      targets: ".logo > div:nth-child(-n+5)",
      duration: 1800,
      marginLeft: function(el, i, l){
        return randomLogoLeft[i]
      },
      width: function(el, i, l){
        return randomLogoWidth[i]
      }
    })

  }, []);


  function handleFocus(){

    setAccess(0)

    setTimeout(() => {
      document.getElementById('focus').focus();
    }, 100);
  }

  function handleButtonClick() {
      $axios.get('/auth/login')
        .then((response) => {
            window.location.href = response.data.login_url
        });
  }


  return (
    <>
      <header className='curtain'>
        <div className='logo'>
          {[...Array(125)].map(x =>
            <div tabIndex="-1">
            </div>
          )}
        </div>

        <div className='nav-box'>
          <nav className='lobby'>
            <Link tabIndex="-1" to="/">
              <button className={access ? 'access' : ''} type="button" title="Navigate to / page.">
                home
              </button>
            </Link>
            <Link tabIndex="-1" to="/about">
              <button className={access ? 'access' : ''} type="button" title="Navigate to about page.">
                about
              </button>
            </Link>
            <button onClick={handleButtonClick} tabIndex="-1" className={access ? 'access' : ''} type="button" title="Navigate to signup page.">
              signup
            </button>
            <button onClick={handleButtonClick} tabIndex="-1" className={access ? 'access' : ''} type="button" title="Navigate to login page.">
              login
            </button>
          </nav>
        </div>
      </header>

      <Outlet />
    </>

  );

}

export default Header
