import { ReactPictureAnnotation, defaultShapeStyle } from "react-picture-annotation";
import { useState, useEffect, useRef } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import axios from '../../../tools/axiosWrapper'
import anime from 'animejs/lib/anime.es.js';


const ViewImage = ({ _list, _now }) => {

  const ref = useRef(null);

  const [pageSize, setPageSize] = useState({
    width: 1,
    height: 1
  });

  useEffect(() => {
    setPageSize({ width: ref.current.offsetWidth, height: ref.current.offsetHeight });
  }, [ref.current]);

  const onResize = () => {
    setPageSize({ width: ref.current.offsetWidth, height: ref.current.offsetHeight  });
  };

  useEffect(() => {

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);


  const [_crops, set_crops] = useState([]);

  const onSelect = selectedId => console.log(selectedId);

  const onChange = data => {
    set_crops(data)
  };

  const submitCrops = (data) => {

    const flash = anime.timeline({
        targets: 'button.submitcrops',
        easing: 'easeInQuad',
        autoplay: false,
      }).add({
        letterSpacing: 2.8,
        fontWeight: '700',
        color: '#FFFFFF',
        duration: 50,
        width: 132,
      }).add({
        letterSpacing: 1.6,
        color: '#B0B0BF',
        fontWeight: 500,
        duration: 300,
        width: 120,
      })

      flash.play()
      console.log(_list[_now].id)
      try {
        axios.post('/tag/create', {
          tagalert: ['crops', Date.now(), _crops],
          imageKey: _list[_now].id
        })
      } catch {

      }
  }


  return (

    <div
      ref={ref}
      className={'view-img'}
      >
      {/*}<TransformWrapper
        >
        <TransformComponent>
          <img
            style={{ maxWidth: '100%', filter: 'brightness(1.3)' }}
            src={ _list.length ? 'data:image/png;base64, ' + _list[_now].img : ''}
            />
        </TransformComponent>
      </TransformWrapper> */}

        <ReactPictureAnnotation
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
          image={ _list.length ? 'data:image/png;base64, ' + _list[_now].img : ''}
          onChange={onChange}
          onSelect={onSelect}
          width={pageSize.width}
          height={pageSize.height}
          scrollSpeed={.00005}
          annotationStyle={{
            ...defaultShapeStyle,
            shapeStrokeStyle: "#FF9E80",
            transformerBackground: "black",
            lineWidth: 5,
            padding: 0,
            fontStyle: 'normal',
            fontColor: 'white',
            fontBackground: '#000',
            fontFamily: '"Gotham SSm A", "Gotham SSm B"',
            lineWidth: 1,
            shapeBackground: "hsla(210, 16%, 93%, 0.2)",
            shapeStrokeStyle: "#000",
            shadowBlur: 10,
            shapeShadowStyle: "hsla(210, 9%, 31%, 0.35)",
            transformerBackground: "#1C1C20",
            transformerSize: 5
          }}
        />

        <button
          className={'submitcrops'}
          onClick={() => submitCrops()}
          style={{
            display: _crops.length ? 'block' : 'none',
            width: 120,
            height: 25,
            backgroundColor: 'rgba(52, 52, 63, .85)',
            borderRadius: 7,
            border: '1px solid #26262F',
            outline: 'none',
            zIndex: 2,
            position: 'absolute',
            right: 34,
            bottom: 32,
            padding: 0,
            fontFamily: '"Gotham SSm A", "Gotham SSm B"',
            fontStyle: 'normal',
            fontSize: 10,
            letterSpacing: 1.2,
            textTransform: 'uppercase',
            fontWeight: 500,
            color: '#B0B0BF'
          }}
          >
          {'submit crops'}
        </button>


    </div>
  );
}


export default ViewImage
