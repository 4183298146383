import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import axios from '../../../tools/axiosWrapper'
import _ from 'lodash';
import './title.css';

const Title = ({_src}) => {


  const {
    register,
    handleSubmit,
    formState,
    setValue,
    getValues
  } = useForm();

  const [_focus, set_focus] = useState(0)


  useEffect(() => {
    if(_src){
      setValue('title', _src.title, { shouldDirty: true })
    }
  },[_src]);

  const onSubmit = async data => {

    set_focus(0)

    axios.post('/doc/update', {
      documentid: _src.documentid,
      title: data.title,
      images: _src.images,
      headings: [_src.headings[0], _src.headings[1], `updatetime=${Date.now()}`]
    })

  };





  const keyPress = (e) => {
     if(e.keyCode === 13){
       e.target.blur();
     }
  }


  const is = (!_focus && (formState.isSubmitted || !(getValues('title')=='Untitled') ) )







  return (
    <form
      className='archtitle'
      onFocus={() => set_focus(1)}
      onBlur={handleSubmit(onSubmit)}
      onKeyDown={(e) => keyPress(e)}
      >
      <input
        type='text'
        placeholder="Untitled"
        style={{
          letterSpacing: is ? '2.2px' : '1.2px',
          color: is ? '#C7C7D1' : '#FFF'
        }}
        {...register("title", {
          minLength: {
            value: 2,
            message: 'error message'
          }
        })}
      />
    </form>
  );

}



export default Title
