import { useState, useEffect, useMemo, useContext } from 'react';
import { useForm } from "react-hook-form";
import axios from '../../tools/axiosWrapper'
import _ from 'lodash';
import './desk.css';

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ArchContext } from '../user/context.js'
import { SlideContext } from './slideContext.js'

import Title from './title/title.js'
import Imgs from './imgs/imgs.js'
import View from './view/view.js'
import Wait from '../../tools/loading.js';

import { ReactComponent as LockSVG } from '../../media/ui_symbols/lock.svg';
import { ReactComponent as MoreSVG } from '../../media/ui_symbols/more.svg';
import { ReactComponent as PlusSVG } from '../../media/ui_symbols/plus.svg';
import { ReactComponent as DocSVG } from '../../media/library/doc_symbol.svg';



const Desk = ({ _my, set_my, location }) => {


  const { id, set_id } = useContext(ArchContext);

  const [ _src, set_src ] = useState(null);
  const [ _waitlist, set_waitlist ] = useState([]);
  const [ _list, set_list ] = useState([]);
  const [ _listtext, set_listtext ] = useState([]);

  const [ _now, set_now ] = useState(0);


  const currentSlide = useMemo(() => {
    return _list[_now]
  }, [_now, _list]);


  const getArch = (slug) => {
    axios
      .get('/doc/list/' + slug)
      .then(res => {
        var data = res.data.contents[0]
        set_src(data)
        try {
          Promise.all(
            data.images.map((key, index) => {
              return axios.get('/img/test/' + key)
                .then(res => {
                  var item = { id: key, img: res.data, ind: index }
                  set_waitlist( old => [...old, item ] )
                  return item
                })
            })).then(i => {
              set_list(i)
            })
        } catch {
        }
      })
  }


  // const getArchText = (slug) => {
  //   axios
  //     .get('/doc/list/' + slug)
  //     .then(res => {
  //       var data = res.data.contents[0]
  //       try {
  //         Promise.all(
  //           data.images.map((key, index) => {
  //             return axios.get('/img/text/' + key)
  //               .then(res => {
  //                 var item = { id: key, text: res.data.text, ind: index }
  //                 return item
  //               })
  //           })).then(i => {
  //             set_listtext(i)
  //           })
  //       } catch {}
  //   })
  // }




  useEffect(() => {
    set_id(location.docid)

  },[location])

  useMemo(() => {
    if(id){
      getArch(id)
      // getArchText(id)
    }
  }, [id, set_id])


  const prevImage = useKeyPress("ArrowLeft");
  const nextImage = useKeyPress("ArrowRight");

  function useKeyPress(targetKey) {
    const [keyPressed, setKeyPressed] = useState(false);
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
    useEffect(() => {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }, []);
    return keyPressed;
  }



  useEffect(() => {
    if(!nextImage){}else{
      if(_now === (_waitlist.length-1)){
      }else{
        set_now(+_now + 1)
      }
    }
  },[nextImage])

  useEffect(() => {
    if(!prevImage){}else{
      set_now(+_now ? +_now - 1 : 0)
    }
  },[prevImage])

  const original = (_src && _src.headings) ? _src.headings.some((i) => i.includes('*--*')) ? 1 : 0 : 0

  return (
    <>
      <SlideContext.Provider value={currentSlide}>
        <main className='desk'>
          <div className='desk'>
            <div className='desk-head'>
              { (!original && _src) ? <LockSVG />  : <></> }
              {
                !original
                ? _src
                  ?(<div className='desk-head-title'>
                      {_src.title}
                    </div>)
                  : <></>
                   : _src
                    ? <Title _src={_src} />
                      : <></>
                }
            </div>

            <div className='desk-main'>
                <section className='list'>
                  <div className='list-head'>
                    <MoreSVG />
                  </div>
                  <div className='list-main'>
                    <div className='list-fade'></div>
                    <Imgs
                      _waitlist={ _.sortBy(_.uniqBy(_waitlist, 'id'), 'ind') }
                      _list={_list}
                      _now={_now}
                      _src={_src}
                      set_now={set_now}
                      set_my={set_my}
                      />
                  </div>
                </section>

              <View
                _src={_src}
                _list={ _list }
                _listtext={_listtext}
                _now={_now}
                _my={_my}
                set_now={set_now}
              />

            </div>

          </div>
        </main>
      </SlideContext.Provider>
    </>
  );

}



export default Desk
