import { Link } from "react-router-dom";
import { ReactComponent as Stacksvg } from '../../media/brand/fbarchive/stack.svg';
import { ReactComponent as PITLLogosvg } from '../../media/brand/other/publicinteresttechlab_logo.svg';
import { ReactComponent as Logo } from '../../media/brand/fbarchive/logo.svg';
import DPLLogosvg from '../../media/brand/other/dataprivacylab_logo.png';
import ShorensteinLogo from '../../media/brand/other/shorenstein_logo.png';
import TASCLogo from '../../media/brand/other/tasc_logo.jpg';

const About = ({ page }) => {

  return (
    <>
        <main className='about' role='main'>
          <Stacksvg />
          <p tabIndex='0' id='focus'>
            We have established <Link to='/'><Logo className='logosvg2'/><span className='logo'>fbarchive.org</span></Link>
            with two primary goals: (1) to provide researchers and journalists around the world with searchable,
            We have established <Link to='/'><Logo className='logosvg2'/><span className='logo'>fbarchive.org</span></Link> with two primary goals: (1) to provide researchers and journalists around the world with searchable,
            indexed access to curated versions of the internal Facebook documents; and (2) to encourage collaborative
            analysis of the documents to improve understanding.
          </p>
          <p tabIndex='0'>
            <Link to='/'><Logo className='logosvg2'/><span className='logo'>fbarchive.org</span></Link> consists of images of documents taken by Frances Haugen, the whistleblower who disclosed tens of thousands of pages
            of internal Facebook research. Teams at Facebook used social science and data analytics to help the company's leadership
            understand adverse consequences from their decisions and technology designs. The documents provide a wealth of material for
            academic and journalistic study and a unique opportunity for collaborative investigation and understanding. We have
            removed personal names and other sensitive content that appear in the images for privacy reasons. We are unable to share
            the raw material as we believe it would be irresponsible to release images without this type of redaction and curation.
          </p>
          <p tabIndex='0'>
            <Link to='/'><Logo className='logosvg2'/><span className='logo'>fbarchive.org</span></Link> is a research project across the
             <Link className='link' to='//techlab.org/'> Public Interest Tech Lab</Link>,
            the <Link className='link' to='//shorensteincenter.org/programs/technology-social-change/' target='_blank'>Technology and Social Change Project</Link>, and
            the <Link className='link' to='//shorensteincenter.org' target='_blank'>Shorenstein Center at Harvard Kennedy School</Link>, Harvard University.
            Our research aims are to construct an innovative tool for collaborative investigation of image artifacts, to curate content
            about the images for improved understanding, and to measure and assess the de-identification of personal names found in
            images. We welcome collaborators and participants.
          </p>

          <h2 className='contact'>
            <Link to='//forms.gle/wcbjLzukzs1rjZcW7' target='_blank'>
              contact us
            </Link>
          </h2>


          <footer className='about'>
              <div className='pitl'>
                <Link title='Navigate to tech lab dot org' to='//techlab.org/' target="_blank" rel="noopener noreferrer">
                  <PITLLogosvg />
                </Link>
              </div>
              <div className='dpl'>
                <Link title='Navigate to data privacy lab dot org' to='//dataprivacylab.org/' target="_blank" rel="noopener noreferrer">
                  <img alt='data privacy lab logo' src={DPLLogosvg} />
                </Link>
              </div>
              <div className='tasc'>
                <Link title='Navigate to technology and social change project' to='//shorensteincenter.org/programs/technology-social-change/' target="_blank" rel="noopener noreferrer">
                  <img alt='tasc logo' src={TASCLogo} />
                </Link>
              </div>
              <div className='shc'>
                <Link title='Navigate to shorenstein center dot org' to='//shorensteincenter.org/' target="_blank" rel="noopener noreferrer">
                  <img alt='shorenstein center logo' src={ShorensteinLogo} />
                </Link>
              </div>

          </footer>

        </main>
    </>

  );

}

export default About
