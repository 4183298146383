const Dot = ({ fill }) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
      <circle fill={ '#' + fill } class="dota" cx="64" cy="64" r="64"/>
    </svg>
  );
}

export default Dot
