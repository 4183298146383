import { useContext } from 'react';
import { Link } from "react-router-dom";
import { useDrop } from 'react-dnd'
import { ArchContext } from '../user/context.js'

import { ReactComponent as DocSVG } from '../../media/library/doc_symbol.svg';

const My = ({ i, reload, set_reload }) => {

  const { id, set_id } = useContext(ArchContext);

  const [{isOver, getDropResult}, drop$my] = useDrop(() => ({
    accept: 'img',
    drop: () => (i),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }))


    return(
      <div className='book-cover' ref={drop$my}>
        <Link to={ 'doc/' + i.documentid } >
          <div className='book'>
            <DocSVG />
            <div className='book-info'>
              <div className='book-title'>
                {i.title}
              </div>
              <div className='book-id'>
                {i.documentid}
                {i.images ? i.images.length : '-'}
              </div>
            </div>
          </div>
        </Link>
      </div>
    )
  }


export default My
