import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

import axios from '../../tools/axiosWrapper'
import _ from 'lodash';

import './library.css';
import Head from '../head/head.js';
import Foot from '../head/head.js';
import Search from './search/search.js'

import Loading from '../../tools/loading.js';
import Col from './shelf/col.js';
import Doc from './shelf/doc.js';
import Img from './shelf/img.js';
import Usr from './shelf/usr.js';

import { ReactComponent as ColSVG } from '../../media/library/col_symbol.svg';
import { ReactComponent as DocSVG } from '../../media/library/doc_symbol.svg';
import { ReactComponent as ImgSVG } from '../../media/library/img_symbol.svg';
import { ReactComponent as FolderSVG } from '../../media/ui_symbols/folder.svg';

const Library = ({ _me }) => {

  const [_browse, set_browse] = useState(null);
  const [_docs, set_docs] = useState([]);
  const [_images, set_images] = useState([]);

  const type = {
    col: {slug: 'col', symbol: <ColSVG/>, original: 1, name: 'collection'},
    doc: {slug: 'doc', symbol: <DocSVG/>, original: 1, name: 'document', },
    img: {slug: 'img', symbol: <ImgSVG/>, original: 1, name: 'image', },
    usr: {slug: 'doc', symbol: <DocSVG/>, original: 0, name: 'document'}
  }

  useEffect(() => {

    if(!_me) return
    Promise.all(
      _me.accessibledocs.map((key, index) => {
        return axios.get('/doc/list/' + key)
          .then(res => {
              var item = {
                title: res.data.contents[0].title,
                id:    res.data.contents[0].documentid,
                src:   res.data.contents[0].headings[0].replace('$#@!*-', '')
              }
              set_docs( _docs => [..._docs, item] )
            })
          })
        )


  }, [_me]);

  return (
    <>
      <div className='library'>
        <Search />

        <section className='library'>
          <div className='library-head'>
            <h1 className='library-head'>
              browse documents
            </h1>
          </div>

          <div className='library-main'>
            <Doc type={ type.doc } list={ _docs } />
          </div>
        </section>
      </div>
    </>
  );

}

export default Library
