import { ReactComponent as Logo } from '../../media/brand/fbarchive/logo.svg';

const Home = ({ page }) => {


  return (
    <>
      <div>
        <div className={'swipe'} tabIndex='-1'>
        </div>
        <main className='home' role='main'>
            <Logo />
            <h1 tabIndex='0' id='focus'>
              <Logo className='logosvg1' /><span className='logo'>fbarchive.org</span> is an online collection of internal Facebook documents designed to facilitate collaborative curation and research.
            </h1>
          </main>
        </div>
      </>

  );

}

export default Home
