import { useForm } from "react-hook-form";
import { useState, useEffect } from 'react';

import './search.css';

const Search = () => {
  return (
    <section className='search'>
      <form className='search'>
        <input type='text' />
      </form>
    </section>
  );
}

export default Search
