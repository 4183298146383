

const ImgWait = ({ item, _now, set_now }) => {


  const now = {
    filter: 'brightness(1.3)',
    opacity: 1,
    border: '2px solid #E07B00'
  };

  const notnow = {
    filter: 'brightness(1.2)',
    opacity: .9,
    border: '2px solid transparent'
  };


  return (
    <>
      <div
        imgindex={item.ind}
        className={'list-item'}
        onClick={(e) => set_now(+e.target.attributes.imgindex.value)}

        >
        <img
          className='list-img'
          src={'data:image/png;base64, ' + item.img}
          style={(_now == item.ind) ? now : notnow}
          />

        <div className='list-info'>
          <h2 className='list-info'>
            {item.ind + '  ' + item.id}
          </h2>
        </div>

      </div>
    </>
  );

}


export default ImgWait
