import { useState, useEffect } from 'react';
import axios from '../../../tools/axiosWrapper'
import _ from 'lodash';
import Magnifier from "react-magnifier";
import Tag from './tag.js'
import AddTo from '../addto/addto.js'
import AddTag from '../tags/addtag.js'

import ViewImage from './viewimage.js'


import { ReactComponent as CommentSVG } from '../../../media/ui_symbols/comment.svg';
import { ReactComponent as DeleteSVG } from '../../../media/ui_symbols/delete.svg';
import { ReactComponent as MinusSVG } from '../../../media/ui_symbols/minus.svg';
import { ReactComponent as PlusSVG } from '../../../media/ui_symbols/plus.svg';
import { ReactComponent as PrivacySVG } from '../../../media/ui_symbols/privacy.svg';
import { ReactComponent as RotateSVG } from '../../../media/ui_symbols/rotate.svg';
import { ReactComponent as SaveSVG } from '../../../media/ui_symbols/save.svg';
import { ReactComponent as LeftArrow } from '../../../media/ui_symbols/leftarrow.svg';
import { ReactComponent as GraphicSVG } from '../../../media/ui_symbols/graphic.svg';


const View = ({ _src, _list, _listtext, _now, _my, set_now }) => {

  const [zoom, setZoom] = useState(1);
  const [_tags, set_tags] = useState([]);


  const actionList= [
    {
      svg: <PrivacySVG />,
      titled: 'privacy alert',
      type: 'privacy',
      desc: 'this image contains sensitve information (names, emails, phone numbers)',
      left: 0
    },
    {
      svg: <CommentSVG />,
      titled: 'custom alert',
      type: 'custom',
      desc: 'custom comment',
      left: 0
    },
    {
      svg: <RotateSVG />,
      titled: 'rotation alert',
      type: 'rotation',
      desc: 'this image is not oriented correctly',
      left: 0
    },
    {
      svg: <GraphicSVG />,
      titled: 'graphic alert',
      type: 'graphic',
      desc: 'this image contains a diagram, graphic',
      left: 0
    }
  ]





  return (
    <>
      <section className='view'>
        <div>

        <div className='view-head'>

          <div className='view-title'>
            <h3 className='view-title'>
              { _listtext.length ? _listtext[_now].id : ''}
            </h3>
            <h4 className='view-title'>
              {_tags.map(i => { return (i + ' ') })}
            </h4>
          </div>

          <div className='view-bar'>
            <AddTo _src={_src} id={_list[_now]} _my={_my} />

            <AddTag _tags={_tags} set_tags={set_tags}/>
          </div>

        </div>

        <div className='view-main'>
        <ViewImage _list={_list} _now={_now}/>

        {/*<div
          className='view-text'
          >
            <div
              className='view-text-field'
              >
              <br/>
              { _listtext.length ? _listtext[_now].text : ''}
              <br/>
              <br/>
            </div>

        </div>*/}


      {/*   <div className='acts'>
         <Tag
            currentImageKey={currentImageKey}
            action={actionList[0]}/>
          <Tag
            currentImageKey={currentImageKey}
            action={actionList[1]}/>
          <Tag
            currentImageKey={currentImageKey}
            action={actionList[2]}/>
          <Tag
            currentImageKey={currentImageKey}
            action={actionList[3]}/>
        </div>*/}
      </div>
      </div>

      </section>
    </>

  );

}


export default View
