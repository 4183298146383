import { useRef } from 'react';
import { useDrag } from 'react-dnd'
import axios from '../../../tools/axiosWrapper'
import { Draggable } from 'react-beautiful-dnd'


const Img = ({ item, _now, set_now, set_my }) => {



  const [{isDragging}, drag$my] = useDrag(() => ({
    type: 'img',
    item: {ind: item.ind, id:item.id},
    end(item, monitor) {
      const arch = monitor.getDropResult()

      try {
        const post = {
            documentid: arch.documentid,
            title: arch.title,
            images: arch.images ? arch.images.concat(item.id) : [item.id],
            headings: [arch.headings[0], arch.headings[1], `updatetime=${Date.now()}`]
        }
        axios
          .post('/doc/update', post)
          .then(i => {
            axios.get('/doc/listme')
              .then(res => {
                  set_my(res.data.contents)
                })
          })
        } catch {}

    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }))


  const now = {
    filter: 'brightness(1.3)',
    opacity: 1,
    border: '2px solid #E07B00'
  };

  const notnow = {
    filter: 'brightness(1.2)',
    opacity: .9,
    border: '2px solid transparent'
  };


  return (
    <>
      <div
        className={'list-item'}
        ref={drag$my}
        imgindex={item.ind}
        onClick={(e) => set_now(+e.target.attributes.imgindex.value)}
        >
        <img
          className={ 'list-img' }

          src={ 'data:image/png;base64, ' + item.img }
          style={ (_now == item.ind) ? now : notnow }
          />

        <div className='list-info'>
          <h2 className='list-info'>
            {item.id}
          </h2>
        </div>

      </div>
    </>
  );

}


export default Img
